import QrScanner from "qr-scanner/qr-scanner.min.js";
import QrScannerWorkerPath from '!!file-loader!../../../node_modules/qr-scanner/qr-scanner-worker.min.js';
QrScanner.WORKER_PATH = QrScannerWorkerPath;
var scanner=""

window.qrScannerInit = function qrScannerInit(){
  const video = document.getElementById('qr-video');
  const camHasCamera = document.getElementById('cam-has-camera');
  const camQrResult = document.getElementById('cam-qr-result');
  const camQrResultTimestamp = document.getElementById('cam-qr-result-timestamp');
  const fileSelector = document.getElementById('file-selector');
  const fileQrResult = document.getElementById('file-qr-result');
  // ####### Web Cam Scanning #######
  QrScanner.hasCamera().then(hasCamera => camHasCamera.textContent = hasCamera);
  scanner = new QrScanner(video, result => {
    if($('#wallets').find(":selected").val() == "other"){
      $("#transaction_wallet_address").val(result)
      $("#transaction_wallet_address").css("borderColor", "green");
      hideUI();
      setTimeout(function(){
        $("#transaction_wallet_address").css("borderColor", "");
      },2000);
    }
    else{
      setResult('unique_id', result)
    }
    qrScannerDestroy()
  });
}

window.qrScannerStart = function qrScannerStart(){
  qrScannerInit()
  scanner.start();
}
window.qrScannerDestroy = function qrScannerDestroy(){
  if (scanner) {
    scanner.destroy();
    scanner = null;  
  }
}

